import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Table, Button, message, Modal} from 'antd';
import queryString from 'query-string';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import JStorageForm from './JStorageForm';
import URL_PATH from '../../UrlPath';
import {
  CONTACT_FORM_STATUS,
  CONTACT_FORM_STATUS_DISPLAY,
} from '../../domain/Constants';
import {format} from '../../utils/date';

const PATH = URL_PATH.dashboardContactUs;
const PAGE_SIZE = 10;
const uiSchema = {
  id: {
    'ui:readonly': true,
  },
  name: {
    'ui:readonly': true,
  },
  email: {
    'ui:readonly': true,
  },
  phone: {
    'ui:readonly': true,
  },
  tel: {
    'ui:readonly': true,
  },
  telExt: {
    'ui:readonly': true,
  },
  title: {
    'ui:readonly': true,
  },
  content: {
    'ui:readonly': true,
    'ui:widget': 'textarea',
  },
};

function ContactUsFormsPage(props) {
  const [records, setRecords] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    current: 1,
    total: 0,
    pageSize: PAGE_SIZE,
  });
  const [tableStateSnapshot, setTableStateSnapshot] = React.useState({});
  const [fetchConfigSnapshot, setFetchConfigSnapshot] = React.useState({
    paging: {
      offset: 0,
      limit: PAGE_SIZE,
    },
    query: {},
    sorting: ['-created'],
  });
  const app = React.useContext(AppContext.Context);
  const params = queryString.parse(props.location.search);
  const selectedId = params.id;

  const schema = {
    title: '編輯表單',
    type: 'object',
    required: ['id'],
    properties: {
      id: {type: 'string', title: 'id'},
      status: {
        type: 'string',
        title: '狀態',
        default: CONTACT_FORM_STATUS.pending,
        anyOf: [
          {
            type: 'string',
            enum: [CONTACT_FORM_STATUS.pending],
            title: CONTACT_FORM_STATUS_DISPLAY.pending,
          },
          {
            type: 'string',
            enum: [CONTACT_FORM_STATUS.contact],
            title: CONTACT_FORM_STATUS_DISPLAY.contact,
          },
          {
            type: 'string',
            enum: [CONTACT_FORM_STATUS.done],
            title: CONTACT_FORM_STATUS_DISPLAY.done,
          },
          {
            type: 'string',
            enum: [CONTACT_FORM_STATUS.cancelled],
            title: CONTACT_FORM_STATUS_DISPLAY.cancelled,
          },
        ],
      },
      name: {
        type: 'string',
        title: '姓名',
      },
      phone: {
        type: 'string',
        title: '聯絡電話',
      },
      email: {
        type: 'string',
        title: 'email',
      },
      tel: {
        type: 'string',
        title: '室內電話',
      },
      telExt: {
        type: 'string',
        title: '室內電話分機',
      },
      title: {
        type: 'string',
        title: '主旨',
      },
      content: {
        type: 'string',
        title: '內容',
      },
      staff_note: {
        type: 'string',
        title: '備註',
        default: '',
      },
    },
  };

  const tableColumns = [
    {
      title: '主旨',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '電話',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      render: (_, record, idx) => {
        return CONTACT_FORM_STATUS_DISPLAY[record.status];
      },
      filteredValue: tableStateSnapshot.filters?.status || null,
      filters: [
        {
          text: CONTACT_FORM_STATUS_DISPLAY.pending,
          value: CONTACT_FORM_STATUS.pending,
        },
        {
          text: CONTACT_FORM_STATUS_DISPLAY.contact,
          value: CONTACT_FORM_STATUS.contact,
        },
        {
          text: CONTACT_FORM_STATUS_DISPLAY.done,
          value: CONTACT_FORM_STATUS.done,
        },
        {
          text: CONTACT_FORM_STATUS_DISPLAY.cancelled,
          value: CONTACT_FORM_STATUS.cancelled,
        },
      ],
    },
    {
      title: '成立時間',
      dataIndex: 'created',
      key: 'created',
      render: (_, record, idx) => {
        return format(record.created, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'created' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.created - b.created,
    },
    {
      title: '更新時間',
      dataIndex: 'updated',
      key: 'updated',
      render: (_, record, idx) => {
        return format(record.updated, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'updated' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.updated - b.updated,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return (
          <Button
            onClick={() => {
              navigate(`${PATH}?id=${record.id}`);
            }}>
            編輯
          </Button>
        );
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return <Button onClick={() => _onDeleteOrder(record)}>刪除</Button>;
      },
    },
  ];

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      if (selectedId) {
        try {
          setSelectedRecord(
            await app.actions.staffGetContactFormById(selectedId),
          );
        } catch (ex) {
          console.warn(ex);
        }
      } else {
        setSelectedRecord(null);
        await _updateOrderList();
      }

      AppActions.setLoading(false);
    }

    if (app.staffUser) {
      fetchData();
    }
  }, [app.actions, selectedId, app.staffUser]);

  const _updateOrderList = React.useCallback(
    async (configs = null) => {
      setIsLoading(true);
      try {
        let _configs = configs || fetchConfigSnapshot;
        let _resp = await app.actions.staffGetContactForms(_configs);
        let _orders = _resp.results;
        setPagination({
          total: _resp.total,
          pageSize: PAGE_SIZE,
          current: _configs.paging.offset / PAGE_SIZE + 1,
        });
        setRecords(_orders);
      } catch (ex) {
        console.warn(ex);
      }
      setIsLoading(false);
    },
    [app.actions, fetchConfigSnapshot],
  );

  const _onDeleteOrder = React.useCallback(
    async (order) => {
      const _confirmDelete = async () => {
        AppActions.setLoading(true);
        let _result = false;
        try {
          let _resp = await app.actions.staffDeleteContactForm(order.id);
          _result = true;
        } catch (err) {
          _result = false;
        }
        AppActions.setLoading(false);

        _result ? message.success('資料已刪除') : message.error('資料刪除失敗');
        await _updateOrderList();
      };

      Modal.confirm({
        title: `是否確定刪除聯絡表單：${order.title}？`,
        content: `表單狀態：${CONTACT_FORM_STATUS_DISPLAY[order.status]}`,
        cancelText: '取消',
        okText: '刪除',
        onOk: _confirmDelete,
      });
    },
    [_updateOrderList],
  );

  const _onDataUpdate = React.useCallback(
    async (formData) => {
      AppActions.setLoading(true);
      let _result = false;
      try {
        await app.actions.staffUpdateContactForm(formData);
        _result = true;
      } catch (err) {
        //
        _result = false;
      }
      AppActions.setLoading(false);

      _result ? message.success('資料已更新') : message.error('資料更新失敗');
    },
    [app.actions],
  );

  const _onTableChange = React.useCallback(
    async (
      pagination, // {current: 1, pageSize: 3}
      filters,
      sorter,
      // currentDataSource: [], action: paginate | sort | filter
      extra,
    ) => {
      let _configs = {
        paging: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: {},
        sorting: ['-created'],
      };

      let _queries = [];
      for (let key of Object.keys(filters)) {
        if (filters[key] && filters[key].length > 0) {
          let _resultTypeQuery = {$or: filters[key].map((v) => ({[key]: v}))};
          _queries.push(_resultTypeQuery);
        }
      }
      _configs.query = _queries.length > 0 ? {$and: _queries} : {};

      if (extra.action === 'paginate') {
        _configs.paging.offset = (pagination.current - 1) * pagination.pageSize;
      }
      if (sorter && sorter.field && sorter.order) {
        _configs.sorting = [
          `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`,
        ];
      }
      AppActions.setLoading(true);
      await _updateOrderList(_configs);
      setFetchConfigSnapshot(_configs);
      setTableStateSnapshot({filters, sorter});
      AppActions.setLoading(false);
    },
    [_updateOrderList],
  );

  if (!selectedRecord) {
    return (
      <Wrapper>
        <h2>聯絡我們表單列表</h2>
        <Table
          locale={{
            triggerDesc: '排序遞減',
            triggerAsc: '排序遞增',
            cancelSort: '取消排序',
          }}
          loading={isLoading}
          dataSource={records}
          columns={tableColumns}
          rowKey={(record) => record.id}
          onChange={_onTableChange}
          pagination={{...pagination, showSizeChanger: false}}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <div>
          <Button
            onClick={() => {
              setSelectedRecord(null);
              navigate(PATH);
            }}>
            返回
          </Button>
        </div>

        <JStorageForm
          schema={schema}
          uiSchema={uiSchema}
          instance={selectedRecord}
          onSubmit={_onDataUpdate}
        />
        <div className="image">
          <img src={selectedRecord.imageUrl} width={'100%'} />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px;

  & > h2 {
    margin-bottom: 16px;
  }
`;

export default ContactUsFormsPage;
